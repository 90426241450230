import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

const StyledDiv = styled.div`
  color: ${(props) => props.theme.textColor};

  .cert-link {
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);

    &:hover {
      -webkit-filter: grayscale(0); /* Chrome, Safari, Opera */
      filter: grayscale(0);
    }
  }
`;

const Certificates = ({ certificatesTitle, certificates, certMaxWidth }) => (
  <StyledDiv className="Certificates">
    <Container fluid className="py-5">
      <Row>
        <Col xs={12} className="text-center">
          <h2 className="font-weight-bold mb-5">{certificatesTitle}</h2>
        </Col>
        <Col className="d-flex flex-wrap align-items-center justify-content-around" xs={12}>
          {certificates.map((certificate, index) => (
            <a
              key={`certificate-${index}`}
              href={certificate.url}
              rel="noopener noreferrer"
              className="cert-link"
              target="_blank"
            >
              <img
                style={{ maxWidth: `${certMaxWidth}px` }}
                src={certificate.logo}
                alt={`certificate-img-${index}`}
              />
            </a>
          ))}
        </Col>
      </Row>
    </Container>
  </StyledDiv>
);

Certificates.propTypes = {
  certificatesTitle: PropTypes.string,
  certMaxWidth: PropTypes.number,
  certificates: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      logo: PropTypes.string,
    })
  ),
};

export default Certificates;

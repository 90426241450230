import React from 'react';
import { graphql, Link } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import Content from '../components/Content';
import Layout from '../components/Layout';
import BackgroundAnimation from '../components/BackgroundAnimation';
import Icon from '../components/Icon';
import Testimonials from '../components/Testimonials';
import Certificates from '../components/Certificates';
import ReadyToGet from '../components/ReadyToGet';

const StyledMain = styled.main`
  .card {
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.textColor};
    min-height: 150px;
  }

  .card:hover {
    transform: scale(1.05);
    transition: transform 0.15s ease-out;
  }
`;

const StyledHr = styled.hr`
  border-color: ${(props) => props.theme.primaryColor};
`;

// Export Template for use in CMS preview
export const HomePageTemplate = ({
  title,
  subtitle,
  body,
  featuresTitle,
  featuresSubtitle,
  features,
  testimonialsTitle,
  testimonials,
  certificatesTitle,
  certMaxWidth,
  certificates,
}) => (
  <StyledMain className="Home">
    <BackgroundAnimation title={title} subtitle={subtitle} />
    <Container fluid className="my-5 py-5" style={{ maxWidth: '1500px' }}>
      <Row>
        <Col xs={12} className="text-center">
          <h3>{featuresTitle}</h3>
          <h4 className="text-muted mb-0">{featuresSubtitle}</h4>
          <StyledHr className="mt-4 mb-5 w-25" style={{ borderWidth: '2px' }} />
        </Col>
        {features.map((feature, index) => (
          <Col xs={12} sm={4} lg={2} key={`feature-${index}`}>
            <Card className="text-center mb-3 border-0 shadow">
              <Card.Body className="px-sm-1 px-lg-2 d-flex align-items-center justify-content-center flex-column">
                <Icon icon={feature.icon} size="2x" />
                <Card.Title as="h5" className="mt-3 mb-0 font-weight-bold">
                  {feature.name}
                </Card.Title>

                <Link to={feature.href} className="stretched-link hidden" />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    {body ? (
      <Container cassName="my-5 py-5">
        <Row>
          <Content source={body} />
        </Row>
      </Container>
    ) : null}
    <Testimonials testimonials={testimonials} testimonialsTitle={testimonialsTitle} />
    <Certificates
      certificates={certificates}
      certificatesTitle={certificatesTitle}
      certMaxWidth={certMaxWidth}
    />
    <ReadyToGet />
  </StyledMain>
);

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
);

export default HomePage;

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuresTitle
        featuresSubtitle
        features {
          name
          icon
          href
        }
        testimonialsTitle
        testimonials {
          quote
          author
        }
        certificatesTitle
        certMaxWidth
        certificates {
          url
          logo
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import styled from 'styled-components';

const StyledDiv = styled.div`
  background-color: ${(props) => props.theme.primaryColor};
  border-color: ${(props) => props.theme.backgroundColor};
`;

const StyledCarousel = styled(Carousel)`
height: 400px;

.carousel-inner {
  height: 100%;
}

@media screen and (min-width: 768px) {
  .caption-text { font-size: 1.25rem; }
}

@media screen and (max-width: 575px) {
  height: 600px;
}
`;

const Testimonials = ({ testimonialsTitle, testimonials }) => (
  <StyledDiv className="angle-top angle-bottom">
    <div className="angle-top-placeholder" />
    <div className="angle-bottom-placeholder" />
    <Container fluid className="text-white py-5" style={{ maxWidth: '1500px' }}>
      <Row>
        <Col xs={12} className="text-center">
          <h2 className="font-weight-bold">{testimonialsTitle}</h2>
        </Col>
        <Col xs={12}>
          <StyledCarousel>
            {testimonials.map((testimonial) => (
              <Carousel.Item key={`testimonial-${testimonial.author}`} className="h-100">
                <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center h-100">
                  <p className="caption-text">{testimonial.quote}</p>
                  <cite> – {testimonial.author}</cite>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </StyledCarousel>
        </Col>
      </Row>
    </Container>
  </StyledDiv>
);

Testimonials.propTypes = {
  testimonialsTitle: PropTypes.string,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
    })
  ),
};

export default Testimonials;

import React, { Component } from 'react';
import styled from 'styled-components';
import Particles from 'react-particles-js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from './Logo';

const StyledHeader = styled.header`
  height: 400px !important;
  background-image: linear-gradient(
    135deg,
    ${(props) => props.theme.primaryColor},
    ${(props) => props.theme.primaryColor}
  );

  .animation-header {
    background-size: cover;
    background-position: center center;
    z-index: 1;
  }

  .jumbotron-content {
    color: ${(props) => props.theme.textColorOnPrimary};
    top: 0;
  }

  .logo {
    width: 100px;
  }

  @media (min-width: 768px) {
    .logo {
      width: 150px;
    }

    & {
      height: 100vh !important;
    }
  }

  @media (min-width: 1200px) {
    .logo {
      width: 200px;
    }
  }
`;

class BackgroundAnimation extends Component {
  state = {
    isMobile: false,
  };

  resize () {
    this.setState({ isMobile: window.innerWidth <= 760 });
  }


  componentDidMount() {
    this.resize()
    console.log(this.state.isMobile)
  }

  render() {
    const { title, subtitle } = this.props;

    return (
      <>
        <StyledHeader
          id="animation-header"
          className="animation-header position-relative w-100 bg-dark overflow-hidden"
        >
          <Particles
            id="demo-canvas"
            className="position-relative h-100"
            params={{
              particles: {
                number: {
                  value: this.state.isMobile ? 10 : 50,
                },
                size: {
                  value: 3,
                },
                move: {
                  speed: 1
                }
              },
              polygon: {
                enable: true,
                move: {
                  radius: 10,
                },
              },
            }}
            style={{ zIndex: '1' }}
          />

          <div className="jumbotron-content text-center position-absolute w-100 h-100 d-flex align-items-center">
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <Logo />
                  <h1 className="mt-2 mb-n2 display-2 font-weight-normal">{title}</h1>
                  <h2 className="h1 mb-0">{subtitle}</h2>
                </Col>
              </Row>
            </Container>
          </div>
        </StyledHeader>
      </>
    );
  }
}

export default BackgroundAnimation;
